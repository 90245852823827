<template>
  <div class="main index" v-if="!isLoading">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Case Study Setup</b></h1>
      </div>
      <div class="column bar">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Case Study</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Case Study Setup</h3>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-2">
        <router-link :to="{ name: `createCaseStudy` }">
          <b-field grouped position="is-right">
            <b-button type="is-hcc" title="Create Case Study">Create</b-button>
          </b-field>
        </router-link>
      </div>
    </div>

    <div class="box">
      <div class="columns is-mobile">
        <div class="column is-12">
          <div class="card card-table">
            <b-tabs v-model="activeTab">
              <!-- For active table -->
              <b-tab-item>
                <template slot="header">
                  <span class="tab">Active</span>
                </template>
                <CaseStudyTableActive v-if="activeTab == 0" />
              </b-tab-item>

              <!-- For inactive table -->
              <b-tab-item>
                <template slot="header">
                  <span class="tab">Inactive</span>
                </template>
                <CaseStudyTableInactive v-if="activeTab == 1" />
              </b-tab-item>

              <!-- For all type table -->
              <b-tab-item>
                <template slot="header">
                  <span class="tab">All Case Study</span>
                </template>
                <CaseStudyTableAllType v-if="activeTab == 2" />
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CaseStudyTableActive from "@/components/CaseStudyTable/CaseStudyTableActive";
import CaseStudyTableInactive from "@/components/CaseStudyTable/CaseStudyTableInactive";
import CaseStudyTableAllType from "@/components/CaseStudyTable/CaseStudyTableAllType";

export default {
  components: {
    CaseStudyTableActive,
    CaseStudyTableInactive,
    CaseStudyTableAllType
  },
  data() {
    return {
      isLoading: false,
      activeTab: 0
    };
  }
};
</script>
<style>
.modal-card-body {
  padding: 20px !important;
}
.modal-card-head,
.modal-card-foot {
  padding: 20px !important;
}
</style>
